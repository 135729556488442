import httpClient from "./httpClient/api";

const getFrame = () => httpClient.get(`/cmtpl/frame`);

const requestContract = (data) => httpClient.post(`/cmtpl/request`, data);

const getAvailableCompanies = (data) => httpClient.post("/cmtpl/companies", data);

const issueContract = (data) => httpClient.post(`/cmtpl/issue`, data);

const getFrameWithPayload = (data) => httpClient.post("/cmtpl/frame/custom", data);

const getContractByRequest = (requestId) => httpClient.get(`/cmtpl/contract/details?requestId=${requestId}`);

const agentSuccess = (token, contractNumber, agentCode) => httpClient.get(`/cmtpl/success/${contractNumber}/${token}/${agentCode}`);

const getActiveContracts = () => httpClient.get(`/cmtpl?filter=1`);

const getExpiredContracts = () => httpClient.get(`/cmtpl?filter=expired`);

const getArchiveContracts = () => httpClient.get(`/cmtpl?filter=2`);

const getContractByNumber = (contractNumber) => httpClient.get(`/cmtpl/contract/${contractNumber}`);

const getContractPdf = (contractNumber) => httpClient.get(`/cmtpl/document/${contractNumber}/pdf`);

const getContractDocumentByNumber = (contractNumber) => httpClient.get(`/cmtpl/document/${contractNumber}`);

export default {
  requestContract,
  getAvailableCompanies,
  issueContract,
  getFrame,
  getFrameWithPayload,
  getContractByRequest,
  agentSuccess,
  getActiveContracts,
  getExpiredContracts,
  getArchiveContracts,
  getContractByNumber,
  getContractDocumentByNumber,
  getContractPdf
}