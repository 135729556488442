import httpClient from "./httpClient/api";

const getCountries = () => httpClient.get(`/travel/countries`);

const getCurrencies = () => httpClient.get(`/travel/currencies`);

const getPeriods = () => httpClient.get(`/travel/periods`);

const requestTravel = (data) => httpClient.post(`/travel`,data);

const calculateTrvlIns = (data) => httpClient.post(`/travel/calculate`,data);

const getActiveContracts = () => httpClient.get(`/travel?filter=1`);

const getArchiveContracts = () => httpClient.get(`/travel?filter=2`);

const getContractByNumber = (policyNumber) => httpClient.get(`/travel/${policyNumber}`);

const getContractPdf = (policyNumber) => httpClient.get(`/travel/document/${policyNumber}/pdf`);

const getContractDocumentByNumber = (policyNumber) => httpClient.get(`/travel/document/${policyNumber}`);


export default {
    getCountries,
    getCurrencies,
    getPeriods,
    requestTravel,
    calculateTrvlIns,
    getActiveContracts,
    getArchiveContracts,
    getContractByNumber,
    getContractDocumentByNumber,
    getContractPdf
}

